// @ts-ignore
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
// @ts-ignore
import cloneDeep from 'lodash-es/cloneDeep';
import {RestService} from "../../../services/rest.service";
import {TranslateService} from "../../../services/translate.service";
// @ts-ignore
import moment from 'moment';
import {Router} from '@angular/router';
import {
    DATE_LAYER_TYPE,
    FETCH_LIMIT,
    LAYERS,
    LETTERS_LAYER_TYPE,
    TreeFilterService
} from '../../../services/tree-filter.service';

// @ts-ignore
@Component({
    selector: 'tree-item',
    templateUrl: 'tree-item.component.html',
    styleUrls: [
        'tree-item.component.less'
    ]
})
export class TreeItemComponent implements OnInit, OnChanges {

    constructor(
        private restService: RestService,
        private router: Router,
        private treeFilterService: TreeFilterService,
        private translate: TranslateService
    ) {
    }

    @Input() public item;
    @Input() public depth;
    @Input() public topOpened = true;
    @Input() public collection;
    @Input() public previousQuery = [];
    @Input() public prepareFetchedItems = items => items;
    @Output() public onNoItemsForLevel = new EventEmitter<any>();

    public opened;
    public fetchedItems;
    public loadingIsInProgress;
    public currentQuery;
    public showLoadMoreButton=false;
    public localizations;
    public currentPage = 0;
    public limit = FETCH_LIMIT;

    ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'tree-filter'
            ]
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.topOpened && !changes.topOpened.currentValue && changes.topOpened.previousValue) {
            this._close();
        }
    }

    async itemClickHandler() {
        this.opened ? this._close() : this._open();
    }

    private async _open() {
        if (!this.currentQuery) {
            await this._createPreviousQuery();
        }
        if (!this.item.children) {
            this.loadingIsInProgress = true;
            await this._fetchItems();
        }

        this.opened = true;
    }

    private _close() {
        this.opened = false;
        delete this.currentQuery;
    }

    async _createPreviousQuery() {
        this.currentQuery = cloneDeep(this.previousQuery);
        if ([LAYERS.DATE.YEAR, LAYERS.DATE.MONTH, LAYERS.DATE.DAY, LAYERS.LETTERS.RANGE, LAYERS.LETTERS.SINGLE].includes(this.item.layer)) {
            const type = [LAYERS.DATE.YEAR, LAYERS.DATE.MONTH, LAYERS.DATE.DAY].includes(this.item.layer)? DATE_LAYER_TYPE : LETTERS_LAYER_TYPE;
            const layer = this.item.layersData
                .find(currentLayer => currentLayer.type === type);

            const layerParams = {
                name: layer.layer,
                value: this.item.value.toString()
            };

            this.item.subTreeQueries = this.item.subTreeQueries || [];
            let subTreeQuery = this.item.subTreeQueries
                .find(subTreeQuery => subTreeQuery.type === type);
            if (!subTreeQuery) {
                subTreeQuery = {
                    type,
                    field: this.item.field,
                    params: [layerParams]
                };
                this.item.subTreeQueries.push(subTreeQuery);
            } else {
                subTreeQuery.params.push(layerParams);
            }
            const nextLayer = this.treeFilterService.getNextLayer(type, layer.layer);
            if (nextLayer) {
                if (type === LETTERS_LAYER_TYPE && nextLayer === LAYERS.LETTERS.TARGET && subTreeQuery.params) {
                    subTreeQuery.params = subTreeQuery.params.filter(param => param.name !== LAYERS.LETTERS.RANGE);
                }
                // получить месяцы или дни для следующего уровня
                const response: any = await this.treeFilterService.getSubTree(this.collection, subTreeQuery); // TODO page? limit?
                const previousChildren = this.item.children;
                this.item.children = [];
                response.fields[0].values
                    .sort()
                    .forEach(subTreeItemData => {
                        const subTreeItem:any = {
                            field: this.item.field,
                            value: subTreeItemData,
                            children: previousChildren,
                            layer: nextLayer,
                            layersData: cloneDeep(this.item.layersData),
                            subTreeQueries: cloneDeep(this.item.subTreeQueries)
                        };
                        const layerType = subTreeItem.layersData.find(layerData => layerData.type === type);
                        if (layerType) {
                            layerType.layer = nextLayer;
                        }

                        if (nextLayer === LAYERS.DATE.MONTH) {
                            subTreeItem.displayName = this.localizations['tree-filter'].month[subTreeItemData] || subTreeItemData;
                        } else {
                            subTreeItem.displayName = subTreeItemData;
                        }

                        this.item.children.push(subTreeItem);
                    });

                return;
            }

            // сформировать запрос в базу
            if (type === DATE_LAYER_TYPE) {
                const value = this._createDateFromSubTree(this.item.subTreeQueries);
                const additionalQuery = {
                    field: this.item.field,
                    operator: 'ge',
                    value
                };
                this.currentQuery.push(additionalQuery);
                const query = {
                    field: this.item.field,
                    operator: 'lt',
                    value: this._getNextDate(value)
                };
                this.currentQuery.push(query);
            } else {
                const value = this._getValueFromLettersRange(this.item.subTreeQueries);
                const query = {
                    field: this.item.field,
                    operator: 'eq',
                    value,
                };
                this.currentQuery.push(query);
            }

            return;
        }

        const query = {
            field: this.item.field,
            operator: 'eq',
            value: this.item.value
        };
        this.currentQuery.push(query);
    }

    private _createDateFromSubTree(subTreeQueries) {
        const dateSubQueries = subTreeQueries.find(subTreeQuery => subTreeQuery.type === DATE_LAYER_TYPE);
        const year = dateSubQueries.params.find(dateSubQuery => dateSubQuery.name === LAYERS.DATE.YEAR);
        const month = dateSubQueries.params.find(dateSubQuery => dateSubQuery.name === LAYERS.DATE.MONTH);
        const day = dateSubQueries.params.find(dateSubQuery => dateSubQuery.name === LAYERS.DATE.DAY);

        return `${year.value}-${month.value}-${day.value}`;
    }

    private _getValueFromLettersRange(subTreeQueries) {
        const targetSubQuery = subTreeQueries.find(subTreeQuery => subTreeQuery.name === LAYERS.LETTERS.TARGET);

        return targetSubQuery.value;
    }

    private _getNextDate(date) {
        const dateObj = moment(date);
        dateObj.add(1, 'days');

        const result = dateObj.format('YYYY-MM-DD');
        return result;
    }

    public async loadMore() {
        this.currentPage++;
    }

    private async _fetchItems() {
        try {
            const items = await this.restService
                .search(this.collection,
                    {
                        search: {
                            search: [
                                {
                                    andSubConditions: this.currentQuery
                                }
                            ]
                        },
                        page: this.currentPage,
                        size: this.limit
                    });

            if (items.length) {
                this.showLoadMoreButton = items.length < this.limit;
                if (!this.currentPage) {
                    this.fetchedItems = this.prepareFetchedItems(items);
                } else {
                    this.fetchedItems = this.fetchedItems + this.prepareFetchedItems(items);
                }

            } else {
                this.item.toDelete = true;
                this.onNoItemsForLevel.emit(this.item);
            }
        } catch (error) {
        }

        this.loadingIsInProgress = false;
    }

    openItem(_id) {
        // направить на страницу редактирования сущности
        const url = `${location.pathname}/edit/${_id}`;
        this.router.navigate([url]);
    }

    emitNoItemForLevelToTheTop(searchQuery) {
        this.onNoItemsForLevel.emit(searchQuery);
    }
}
