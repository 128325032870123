import { Component, Input, OnInit } from '@angular/core';
import {TranslateService} from "../../services/translate.service";

@Component({
    selector: 'filters-bar-settings',
    templateUrl: './filters-bar-settings.component.html',
    styles: [`
        .remove-btn{
            position: absolute;
            top: 21px;
            right: 21px;
            padding: 5px 10px;
            cursor: pointer
        }
    `]
})
export class FiltersBarSettingsComponent implements OnInit {
    @Input() blocks = [];

    public localizations;

    constructor(
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'filter-bar-settings'
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    onDrop(event) {
        let index = event.index;

        if (typeof index === 'undefined') {
            index = this.blocks.length;
        }

        this.blocks.splice( index, 0, event.data );
    }

    onDragged(event, index) {
        this.blocks.splice( index, 1 );
    }

    addBlock() {
        this.blocks.push({name: '', code: '', filters: []});
    }

    removeBlock(index) {
        this.blocks.splice(index, 1);
    }

    addFilter(block) {
        block.filters.push({});
    }
}
