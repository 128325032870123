import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TranslateService } from '../../services/translate.service';
import { CORE_CONFIG } from '../../infrastructure/core-config.injectionToken';
import { CoreDynamicConfigInterface } from '../../infrastructure/core-dynamic-config.interface';

@Component({
    selector: 'about-modal-dialog',
    templateUrl: 'about-modal-dialog.component.html',
})
export class AboutModalDialogComponent implements OnInit {

    @ViewChild('dialogTemplate', { static: false }) public dialogTemplate: TemplateRef<any>;

    private modalRef: BsModalRef;
    public localizations;

    constructor(
        private modalService: BsModalService,
        private translate: TranslateService,
        @Inject(CORE_CONFIG) public readonly config: CoreDynamicConfigInterface,
    ) {
    }

    public showModal() {
        this.modalRef = this.modalService.show(this.dialogTemplate, { backdrop: 'static' });
    }

    public ngOnInit(): void {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public hideModal() {
        this.modalRef.hide();
    }

    public handleCloseClick() {
        this.hideModal();
    }

}
