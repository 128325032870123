import { Component, Input, OnInit } from '@angular/core';

import { UserNotificationsSettingService } from '../../../../../services/user-notifications-setting.service';
import { TranslateService } from "../../../../../services/translate.service";

@Component({
    selector: 'user-notifications-setting',
    templateUrl: './user-notifications-setting.component.html',
    styleUrls: [
        './user-notifications-setting.component.scss'
    ]
})
export class UserNotificationsSettingComponent implements OnInit {
    localizations;

    @Input() user: any; // Пользователь
    @Input() moduleBaseUrl: string; // Базовый URL основного приложения

    constructor(
        public userNotificationsSettingService: UserNotificationsSettingService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this._loadTranslations();
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'users',
                'notifications-preview'
            ]
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }
}
