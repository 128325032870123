import { ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { CustomElementSettingsComponent } from './custom-element-settings-component.model';

export enum numberTypes {
  INTEGER,
  DOUBLE,
}

export interface FormsConstructorParams {
    isSetVariable?: boolean;
    isTransferToProcess?: boolean;
    isSetTransferToProcess?: boolean;
    isInitValueFromExternalData?: boolean;
    isInitDataFromExternalData?: boolean;
    isSaveInMainComponent?: boolean;
    documentGroups?: [any];
    numberType?: numberTypes;
    customElementSettingsComponentFactoryResolver?: (resolver: ComponentFactoryResolver) => ComponentFactory<CustomElementSettingsComponent>;
}
