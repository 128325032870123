import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { CORE_CONFIG } from '../infrastructure/core-config.injectionToken';
import { CoreDynamicConfigInterface } from '../infrastructure/core-dynamic-config.interface';
import { RestService } from './rest.service';
import { ToasterService } from './toaster.service';
import { FilesService } from './files.services';

@Injectable()
export class ReportsService {
    public reportCreationIsInProgress;

    constructor(
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private httpService: HttpService,
        private restService: RestService,
        private filesService: FilesService,
        private toasterService: ToasterService
    ) {
    }

    public async startGeneratingRegistryModelReport(metaReglamentCode, searchCriteria) {
        try {
            this.reportCreationIsInProgress = true;
            const params = {search: {search: searchCriteria || []}};
            const response = await this.httpService.post(this.config.endpoints.server + 'api/tatar/v1/' + 'rendering/' + metaReglamentCode + '/licenses', params);
            this._waitResultReport(response);
        } catch (error) {
            this.reportCreationIsInProgress = false;

            return error;
        }
    }

    private _getResultReport(params) {
        // запрос на получение готового отчета
        return this.restService.find('reports', params._id);
    }

    /**
     * Пингуем серевер пока формируется отчет
     * @param params
     */
    private async _waitResultReport(params) {
        await new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
        const response: any = await this._getResultReport(params);
        if (response.status === 'completed') {
            // обработка результата
            this.filesService.downloadAndSaveFile(response.file._id, response.file.name);
            this.reportCreationIsInProgress = false;
        } else if (response.status === 'error') {
            this.reportCreationIsInProgress = false;
            this.toasterService.error(response.description || 'При создании отчета произошла ошибка');
        } else {
            return this._waitResultReport(params);
        }
    }

}
