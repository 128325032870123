import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DATE_LAYER_TYPE, LAYERS, LETTERS_LAYER_TYPE, TreeFilterService } from '../../services/tree-filter.service';
import { TranslateService } from '../../services/translate.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'tree-filter',
    templateUrl: 'tree-filter.component.html',
    styleUrls: [
        'tree-filter.component.less'
    ]
})
export class TreeFilterComponent implements OnInit, OnChanges {

    // TODO перенести в конфиги
    private static LEVELS_LIMIT = 10;

    // список предуказанных уровней
    @Input() public treeFilter;
    @Input() public enabled;

    // TODO доработать
    @Input() public page=0;
    @Input() public treeLevelsToClear;
    @Output() onEnabledChange = new EventEmitter<any>();

    public levelsData;
    public structuredLevels;
    public localizations;

    constructor(
        private treeFilterService: TreeFilterService,
        private translate: TranslateService,
        private toasterService: ToasterService
    ) {
    }

    ngOnInit(): void {
        this._loadTranslations();
        this._prepareLevels();
        this.treeFilterService.collectionName = this.treeFilter.collection;
    }

    /**
     * Обработчик включения фильтра
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.enabled && changes.enabled.currentValue) {
            this.treeFilterService.prepareFetchedItems = this.treeFilter.prepareFetchedItems;
            this._init();
        }

        if (changes.treeLevelsToClear && changes.treeLevelsToClear.currentValue) {
            setTimeout(() => {
                this._removeLevel();
            });
        }
    }

    _removeLevel() {
        this.treeFilterService.tree = this.treeFilterService.tree.filter(level => this._filterChildren(level));
    }

    _filterChildren(level) {
        if (level.toDelete) {
            return false;
        }

        if (level.children) {
            level.children = level.children.filter(child => this._filterChildren(child));

            return !!level.children.length;
        }

        return true;
    }

    _prepareLevels() {
        this.structuredLevels = new Array(this.treeFilter.levelsNumber);
        this.levelsData = this.treeFilter.levels.map(avaliableLevel => ({
            value: avaliableLevel,
            displayName: this._localizeFiled(avaliableLevel)
        }));
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'tree-filter'
            ]
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    private async _init() {
        // есть ли вообще данные уровней
        if (!this.structuredLevels || !this.structuredLevels.length) {
            this.toasterService.error(this.localizations['tree-filter'].notifications.incorrect_levels);
            this.enabled = false;
            this.onEnabledChange.emit(false);
            return;
        }

        // все ли данные уровней заданы
        if (Object.values(this.structuredLevels).length !== this.structuredLevels.length) {
            this.toasterService.error(this.localizations['tree-filter'].notifications.some_levels_are_not_set);
            this.enabled = false;
            this.onEnabledChange.emit(false);
            return;
        }

        this.structuredLevels = this._initLayers(this.structuredLevels);
        const levels = this.structuredLevels.map(level => level.value);

        const response: any = await this.treeFilterService
            .getTree(
                this.treeFilter.collection,
                levels,
                this.page,
                TreeFilterComponent.LEVELS_LIMIT
            );
        const levelsContent = response.fields;
        this.treeFilterService.tree = this._createTreeFromLevelContent(levelsContent);
    }

    private _initLayers(structuredLevels) {
        structuredLevels.map(sl => {
            // { value, displayName }
            if (sl.value.includes('date') || sl.value.includes('Date')) {
                sl.layer = 'year';
            }
        });

        return structuredLevels;
    }

    // TODO проставить тип объекта
    public getLevelName(level: any, index: number) {
        if (level) {
            return level.displayName;
        }

        return index + 1 + ' ' + this.localizations['tree-filter'].level;
    }

    public onDragged(level) { // TODO нужны ли все эти параметры
        this.levelsData = this.levelsData.filter(lev => lev.value !== level.value);
    }

    onDrop(event, index) {
        this.structuredLevels[index] = event.data;
    }

    private _addItems(levelsContent, depth) {
        const items = levelsContent[depth].values.map(content => {
            const result: any = {
                field: this.structuredLevels[depth].value,
                value: content,
                displayName: this._localize(depth, content)
            };

            const layer = levelsContent[depth].layer;
            if (layer) {
                result.layer = layer;
                if (layer === LAYERS.DATE.YEAR) {
                    result.layersData = [{
                        type: DATE_LAYER_TYPE,
                        layer
                    }];
                }

                if (layer === LAYERS.LETTERS.RANGE) {
                    result.layersData = [{
                        type: LETTERS_LAYER_TYPE,
                        layer
                    }];
                }
            }

            if (depth + 1 < this.treeFilter.levelsNumber) {
                result.children = this._addItems(levelsContent, depth + 1);
            }

            return result;
        });

        return items;
    }

    private _createTreeFromLevelContent(levelsContent) {
        if (levelsContent.some(levelsContent => !levelsContent.values.length)) {
            this.toasterService.error(this.localizations['tree-filter'].notifications.insufficient_levels_content);
            return;
        }

        const tree = levelsContent[0].values.map(content => {
            const item: any = {
                field: this.structuredLevels[0].value,
                value: content,
                displayName: this._localize(0, content),
                children: this._addItems(levelsContent, 1)
            };

            const layer = levelsContent[0].layer;
            if (layer) {
                item.layer = layer;
                if (layer === LAYERS.DATE.YEAR) {
                    item.layersData = [{
                        type: DATE_LAYER_TYPE,
                        layer
                    }];
                }

                if (layer === LAYERS.LETTERS.RANGE) {
                    item.layersData = [{
                        type: LETTERS_LAYER_TYPE,
                        layer
                    }];
                }
            }


            return item;
        });

        return tree;
    }

    private _localize(i, value) {
        const categoryName = this.structuredLevels[i].value;
        if (!this.localizations['tree-filter'][categoryName] || !this.localizations['tree-filter'][categoryName][value]) {
            return value;
        }

        const locs = this.localizations['tree-filter'][categoryName][value];

        return locs;
    }

    private _localizeFiled(value) {
        return this.localizations['tree-filter'].fields[value.replace('.', '_')] || value;
    }
}
