import { Injectable } from '@angular/core';
import { DatepickerDate } from '../components/datapicker/models/datepicker-date.model';
import * as moment_ from 'moment';

const moment = moment_;

@Injectable()
export class DatepickerService {

    public static DisableUntil(startDate: any): DatepickerDate {
        if (startDate) {
            const date = moment(startDate);
            const day = date.date() === 1 ? date.subtract(1, 'days').date() : date.date() - 1;

            return { year: date.year(), month: date.month() + 1, day: +day };
        }

        return { year: 0, month: 0, day: 0 };
    }

    public static DisableSince(endDate: any): DatepickerDate {
        if (endDate) {
            const date = moment(endDate);
            const day = (date.date() === 30 || date.date() === 31) ? date.add(1, 'days').date() : date.date() + 1;

            return { year: date.year(), month: date.month() + 1, day: +day };
        }

        return { year: 0, month: 0, day: 0 };
    }
}
