// @ts-ignore
import { Inject, Injectable } from '@angular/core';
import { CORE_CONFIG } from '../infrastructure/core-config.injectionToken';
import { CoreDynamicConfigInterface } from '../infrastructure/core-dynamic-config.interface';
import { HttpService } from './http.service';

export const LAYERS = {
    DATE: {
        YEAR: 'year',
        MONTH: 'month',
        DAY: 'day'
    },
    LETTERS: {
        RANGE: 'lettersRange',
        SINGLE: 'letters',
        TARGET: 'strings'
    }
};
export const LAYER_TYPES = {
    DATE: 'dateRange',
    LETTERS: 'lettersRange'
}
export const DATE_LAYER_TYPE = 'date';
export const LETTERS_LAYER_TYPE = 'letters';
export const FETCH_LIMIT = 20;

// @ts-ignore
@Injectable()
export class TreeFilterService {

    public tree;
    public collectionName;
    public prepareFetchedItems;
    private readonly BASE_URL;
    private DEFAULT_PAGE = 0;
    private DEFAULT_LIMIT = 10;

    constructor(
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private httpService: HttpService
    ) {
        this.BASE_URL = config.endpoints.server + 'api/tatar/v1/tree-filter/get/levels';
    }

    public getNextLayer(type, current) {
        const typeLayers = LAYERS[type.toUpperCase()];
        const layers = Object.values(typeLayers);
        const currentLayerIndex = layers.findIndex(layer => layer === current);
        if (currentLayerIndex < layers.length - 1) {
            return layers[currentLayerIndex + 1];
        }

        return null;
    }

    public getTree(collectionName: string, levels: any, page?: number, limit?: number) {
        levels = levels.map(level => ({field: level}));

        return this._getTreeRequest(collectionName, levels, page, limit);
    }

    public getSubTree(collectionName, params, page?: number, limit?: number) {
        const levels = {
            field: params.field,
            params: params.params
        };

        return this._getTreeRequest(collectionName, levels, page, limit);
    }

    private _getTreeRequest(collectionName, levels, page, limit) {
        page = page || this.DEFAULT_PAGE;
        limit = limit || this.DEFAULT_LIMIT;
        const data = {
            collectionName,
            levels
        };

        return this.httpService.post(this.BASE_URL, data);
    }
}
