/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {APPLICATION_MODULE_PROVIDERS as Éµangular_packages_core_core_q,_iterableDiffersFactory as Éµangular_packages_core_core_n,_keyValueDiffersFactory as Éµangular_packages_core_core_o,_localeFactory as Éµangular_packages_core_core_p,zoneSchedulerFactory as Éµangular_packages_core_core_r} from './src/application_module';
export {_appIdRandomProviderFactory as Éµangular_packages_core_core_f} from './src/application_tokens';
export {DefaultIterableDifferFactory as Éµangular_packages_core_core_l} from './src/change_detection/differs/default_iterable_differ';
export {DefaultKeyValueDifferFactory as Éµangular_packages_core_core_m} from './src/change_detection/differs/default_keyvalue_differ';
export {DebugElement__PRE_R3__ as Éµangular_packages_core_core_k,DebugNode__PRE_R3__ as Éµangular_packages_core_core_j} from './src/debug/debug_node';
export {NullInjector as Éµangular_packages_core_core_b,injectInjectorOnly as Éµangular_packages_core_core_a} from './src/di/injector_compatibility';
export {ReflectiveInjector_ as Éµangular_packages_core_core_c} from './src/di/reflective_injector';
export {ReflectiveDependency as Éµangular_packages_core_core_d,resolveReflectiveProviders as Éµangular_packages_core_core_e} from './src/di/reflective_provider';
export {getModuleFactory__PRE_R3__ as Éµangular_packages_core_core_i} from './src/linker/ng_module_factory_loader';
export {wtfEnabled as Éµangular_packages_core_core_s} from './src/profile/profile';
export {createScope as Éµangular_packages_core_core_u,detectWTF as Éµangular_packages_core_core_t,endTimeRange as Éµangular_packages_core_core_x,leave as Éµangular_packages_core_core_v,startTimeRange as Éµangular_packages_core_core_w} from './src/profile/wtf_impl';
export {SCHEDULER as Éµangular_packages_core_core_ba} from './src/render3/component_ref';
export {injectAttributeImpl as Éµangular_packages_core_core_bb} from './src/render3/di';
export {TElementNode as Éµangular_packages_core_core_bf} from './src/render3/interfaces/node';
export {LView as Éµangular_packages_core_core_bj} from './src/render3/interfaces/view';
export {getLView as Éµangular_packages_core_core_bc,getPreviousOrParentTNode as Éµangular_packages_core_core_bd,nextContextImpl as Éµangular_packages_core_core_be} from './src/render3/state';
export {getRootContext as Éµangular_packages_core_core_bm} from './src/render3/util/view_traversal_utils';
export {loadInternal as Éµangular_packages_core_core_bl} from './src/render3/util/view_utils';
export {createElementRef as Éµangular_packages_core_core_g,createTemplateRef as Éµangular_packages_core_core_h} from './src/render3/view_engine_compatibility';
export {getUrlSanitizer as Éµangular_packages_core_core_bg} from './src/sanitization/sanitization';
export {noSideEffects as Éµangular_packages_core_core_bk} from './src/util/closure';
export {makeParamDecorator as Éµangular_packages_core_core_bh,makePropDecorator as Éµangular_packages_core_core_bi} from './src/util/decorators';
export {getClosureSafeProperty as Éµangular_packages_core_core_bn} from './src/util/property';
export {_def as Éµangular_packages_core_core_y} from './src/view/provider';
export {DebugContext as Éµangular_packages_core_core_z} from './src/view/types';