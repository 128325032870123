import {Component, Input, OnInit} from '@angular/core';
import { CommonUtilities } from '@evolenta/utilities';
import { TranslateService } from "../../services/translate.service";

@Component({
    selector: 'filters-bar-settings-block-filter',
    templateUrl: './filters-bar-settings-block-filter.component.html',
    styleUrls: [
        'filters-bar-settings-block-filter.component.less'
    ]
})
export class FiltersBarSettingsBlockFilterComponent implements OnInit {
    @Input() filter;

    public filterCode: string = CommonUtilities.GenerateGuid();
    public filterFields: string;
    public levelsText: string;
    public localizations;

    constructor(
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this._loadTranslations();
        if (this.filter.fields) {
            this.filterFields = this.filter.fields.join(',');
        }
        if (this.filter.type === 'checkboxes' && this.filter.items && Array.isArray(this.filter.items)) {
            this.filter.items = this.filter.items.map(item => ({...item, codeString: item.code.join(',')}));
        }

        if (this.filter.levels) {
            this.levelsText = this.filter.levels.join('\n');
        }

    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'events',
                'reports',
                'dictionaries',
                'filter-bar-settings'
            ],
        ).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    changeFilterFields() {
        if (this.filterFields) {
            this.filter.fields = this.filterFields
                .split(',')
                .map(item => item.trim())
                .filter(item => item);
        } else {
            this.filter.fields = [];
        }
    }

    changeType(type) {
        this.filter.type = type;
        if (this.filter.type === 'period') {
            this.filter.dates = [];
            this.filter.items = [
                {
                    code: 'today',
                    name: this.localizations.events.today
                },
                {
                    code: 'week',
                    name: this.localizations.events.week
                },
                {
                    code: 'month',
                    name: this.localizations.events.month
                },
                {
                    code: 'interval',
                    name: this.localizations.events.interval
                }
            ];
            this.filter.itemName = 'text';
            this.filter.itemValue = 'id';
        } else if (this.filter.type === 'dictionary') {
            this.filter.dictionary = {
                name: '',
                field: 'code'
            };
            this.filter.labelField = 'name';
        }
        this.clearFilterData();
    }

    clearFilterData() {
        const types = {
            input: ['placeholder'],
            period: ['items', 'itemName', 'itemValue', 'dates'],
            checkboxes: ['items', 'operator', 'storageItem', 'itemStyleProperty'],
            dictionary: ['dictionary', 'labelField'],
            checkbox: ['useValue']
        };
        Object.keys(this.filter).forEach(field => {
            if (!['code', 'fields', 'type'].includes(field) && types[this.filter.type] && !types[this.filter.type].includes(field)) {
                delete this.filter[field];
            }
        });
    }

    public parseTreeFilterLevels() {
        this.filter.levels = this.levelsText.split('\n');
    }

    addDate() {
        this.filter.dates.push({id: '', text: ''});
    }

    changeCheckboxesType(type) {
        this.filter.checkboxesType = type;
        switch (this.filter.checkboxesType) {
            case 'special':
                this.filter.itemStyleProperty = 'background';
                delete this.filter.storageItem;
                this.filter.items = '';
                delete this.filter.operator;
                break;
            case 'storage':
                delete this.filter.items;
                delete this.filter.itemStyleProperty;
                delete this.filter.operator;
                break;
            case 'values':
                this.filter.items = [];
                this.filter.operator = 'in';
                delete this.filter.storageItem;
                delete this.filter.itemStyleProperty;
                break;
        }
    }

    isFilterCheckboxesTypeSelected(type) {
        return this.filter.type === 'checkboxes' &&
            ((type === 'special' && (this.filter.checkboxesType === 'special' || typeof this.filter.items === 'string' && this.filter.items.indexOf('@') !== -1))
               || (type === 'storage' && (this.filter.checkboxesType === 'storage' || this.filter.storageItem))
               || (type === 'values' && (this.filter.checkboxesType === 'values' || Array.isArray(this.filter.items))));
    }

    addValue() {
        this.filter.items.push({code: [], codeString: '', name: ''});
    }

    changeValueCode(val) {
        if (val.codeString) {
            val.code = val.codeString
                .split(',')
                .map(elm => elm.trim())
                .filter(item => item);
        } else {
            val.code = [];
        }
    }

    public changeSpecialElementToStatuses(filter: any): void {
        filter.items = '@statuses';
        if (!filter.itemName && !filter.itemValue) {
            filter.itemName = 'name';
            filter.itemValue = 'code';
        }
    }

    public changeSpecialElementToOperationTypes(filter: any): void {
        filter.items = '@operationTypes';
        if (filter.itemName && filter.itemValue) {
            delete filter.itemName;
            delete filter.itemValue;
        }
    }
}
