import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbItemDirective } from './directives/breadcrumb-item.directive';
import { BreadcrumbComponent } from './components/xng-breadcrumb/breadcrumb.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        BreadcrumbsComponent,
        BreadcrumbItemDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [
        BreadcrumbComponent,
        BreadcrumbsComponent,
        BreadcrumbItemDirective,
    ],
})
export class BreadcrumbsModule {
}
