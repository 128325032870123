import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '../../../../../services/translate.service';
import get from 'lodash-es/get';
import {RestService} from "../../../../../services/rest.service";

export const FRONTEND_FILTERS_COLLECTION = 'frontendFilters';

export const mandatoryFilters = [
    'noLimitByUnit',
    'appealsActuality',
    'appealStatuses',
    'appealSource',
];

@Component({
    selector: 'user-filters-config',
    templateUrl: './user-filters-config.component.html',
    styleUrls: [
        './user-filters-config.component.scss'
    ]
})
export class UserFiltersConfigComponent implements OnInit {
    @Input() user: any;

    public initialized;
    public filters;
    private localizations;

    constructor(
        private translate: TranslateService,
        private restService: RestService
    ) {
    }

    public localize(key) {
        return get(this.localizations, key, this.localizations.users.wrong_filter);
    }

    async ngOnInit(): Promise<void> {
        delete this.user.password;
        this._loadTranslations();
    }

    private async _getFilters() {
        const params = {search: {search: []}};
        this.filters = await this.restService.search(FRONTEND_FILTERS_COLLECTION, params);
        this.filters = this.filters.filter(filter => !mandatoryFilters.includes(filter.code));
    }

    private _loadTranslations() {
        this.translate.get(
            [
                'common',
                'filters',
                'users'
            ]
        ).subscribe((res: any) => {
            this.localizations = res;
            this._getFilters();
            this.initialized = true;
        });
    }

    public checkCheckboxIsChecked(filter) {
        return this.user && this.user.filters && this.user.filters.some(filt => filt.name === filter.name);
    }

    public checkboxChanged(event, filter) {
        this.user.filters = this.user.filters || [];
        if (event.target.checked) {
            if (!this.user.filters || !this.user.filters.some(filt => filt.name === filter.name)) {
                this.user.filters.push(filter);
            }
        } else {
            this.user.filters = this.user.filters.filter(filt => filt.name !== filter.name);
        }

        return this.restService.update('users', this.user);
    }
}
