import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RestService, SelectionService } from '@evolenta/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'evolenta-entity-requests',
    templateUrl: 'entity-requests.component.html',
})
export class EntityRequestsComponent implements OnInit {
    @Input() entity;
    @Input() requests = [];

    @Output() onChangeRequests = new EventEmitter<boolean>();

    constructor(
        private rest: RestService,
        private router: Router,
        private selectionService: SelectionService,
    ) {
    }

    /**
     * Инициализация компонента, получение данных
     */
    ngOnInit() {
        if (!this.entity.requests) {
            this.entity.requests = [];
        }
        if (this.selectionService.isProcessSelect) {
            this.completeSelectRequests();
        } else {
            if (this.entity.requests && this.entity.requests.length > 0 && this.requests.length === 0) {
                this.rest.search('requests', {search: {search: [{field: '_id', operator: 'in', value: this.entity.requests}]}, size: 100}).then((requests) => {
                    this.requests = requests;
                });
            }
        }
    }

    completeSelectRequests() {
        if (this.selectionService.isProcessSelect) {
            let hasChanges = false;
            this.requests = cloneDeep(this.selectionService.additionalData.requests);
            this.selectionService.selectedItems.forEach((request) => {
                const find = this.requests.find((item) => item._id === request._id);
                if (!find) {
                    this.requests.push(request);
                }
                const findInEntity = this.entity.requests.indexOf(request._id);
                if (findInEntity === -1) {
                    this.entity.requests.push(request._id);
                    hasChanges = true;
                }
            });
            if (hasChanges) {
                this.onChangeRequests.emit(true);
            }
            this.selectionService.clearData();
        }
    }

    selectRequests() {
        this.selectionService.isProcessSelect = true;
        this.selectionService.transferObject = this.entity;
        this.selectionService.selectedItems = [];
        this.selectionService.transferBackUrl = [this.router.url];
        this.selectionService.transferOperation = 'selectRequests';
        this.selectionService.additionalData = {
            requests: this.requests,
        };

        this.router.navigate(['selecting', 'requests']);
    }

    deleteRequest(request) {
        const findIndexInEntity = this.entity.requests.indexOf(request._id);
        this.entity.requests.splice(findIndexInEntity, 1);
        const findIndex = this.requests.findIndex((item) => item._id === request._id);
        if (findIndex === -1) {
            this.requests.splice(findIndex, 1);
        }
        this.onChangeRequests.emit(true);
    }
}
